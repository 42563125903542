/* COCKPIT PRINCIPAL */

/* LABELS */

.dashboard-titulo .ant-divider-inner-text {
  color: gray;
  font-size: 30px;
  padding-top: 20px;
}

.resultado-texto {
  font-size: 40px;
  text-align: right;
  margin-bottom: 0;
  color: gray;
}

.resultado-texto-erro {
  font-size: 12px;
  text-align: right;
  margin-bottom: 0;
  color: gray;
  padding-top: 5px;
}

/* 
.resultado-header {
  font-size: 15px;
  text-align: right;
  margin-bottom: 0;
  color: gray;
} */

/* CARD HEADER*/

.card-atendimentos .ant-card-head {
  background: linear-gradient(90.45deg, #2631a7 -1.37%, #6771dc 105.22%);
}

.card-mensagens .ant-card-head {
  background: linear-gradient(87.62deg, #106ec9 -25.11%, #1882e7 52.55%, #1d8efa 102.33%);
}

.card-usuarios .ant-card-head {
  /* background: linear-gradient(85.72deg, #404dd8 15.23%, #1469b9 74.88%); */
  background: linear-gradient(95.27deg, #2d9afe 0%, #53acff 99.44%);
}

.card-media-satisfacao .ant-card-head {
  background-image: linear-gradient(to right, #42b8a8, #86d5ca);
  border: none;
  box-shadow: 0px 0px 3px #42b8a8;
}

.card-atend-finalizado .ant-card-head {
  background-image: linear-gradient(to right, #21a628, #258a2a);
  border: none;
  box-shadow: 0px 0px 3px green;
}

.card-atend-nfinalizado .ant-card-head {
  background-image: linear-gradient(to right, #eb910c, #fd9c0b);
  border: none;
  box-shadow: 0px 0px 3px orangered;
}

/* CARD SPANS */

.card-span-total-atendimentos {
  font-size: 45px;
  color: white;
}

.card-span-total-mensagens {
  font-size: 45px;
  color: white;
}

.card-span-media-satisfacao {
  font-size: 45px;
  color: white;
}

.card-span-total-atend-finalizado {
  font-size: 45px;
  color: white;
}

.card-span-total-atend-nfinalizado {
  font-size: 45px;
  color: white;
}

/* COCKPIT SIDER, MENU */

.logged-user {
  background: #203b56;
  width: auto;
  padding-bottom: 15px;
  padding-top: 15px;
}

.logged-user-texto {
  font-size: 15px;
  color: white;
  margin: 5px;
}

/* COCKPIT atendimentoS CHAT */

.bolha-atendimento-atendente {
  position: relative;
  color: #fff;
  background: #163564;
  border-radius: 0.4em;
  box-shadow: 1px 1px 10px #73726a;
}

.bolha-atendimento-atendente:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-left-color: #fff7fd;
  border-right: 0;
  border-bottom: 0;
  margin-top: -6px;
  margin-right: -12px;
}

/* ANIMATIONS */

.pontoUm {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0s;
  animation: dot 1.3s infinite;
  animation-delay: 0s;
}

.pontoDois {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
  animation: dot 1.3s infinite;
  animation-delay: 0.2s;
}

.pontoTres {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
  animation: dot 1.3s infinite;
  animation-delay: 0.3s;
}

@-webkit-keyframes dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.nova_mensagem {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  color: white;
}

.nova_mensagem:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.popover-export {
  margin: 0px 10px 0px;
}

.btn-export {
  margin: 0px 10px 0px;
}

/* BODY */

.leads-footer-propriedades {

    background: #203b56;
    color: white;
    bottom: 0;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 15px;
    padding: 10px;

}
.shadow-1dp {
    box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.14),
                0 2px 2px 0 rgba(210, 210, 210, 0.12),
                0 1px 3px 0 rgba(210, 210, 210, 0.20);
  }
  
  .shadow-2dp {
    box-shadow: 0 2px 4px 0 rgba(210, 210, 210,0.14),
                0 3px 4px 0 rgba(210, 210, 210,0.12),
                0 1px 5px 0 rgba(210, 210, 210,0.20);
  }
  
  .shadow-3dp {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14),
                0 3px 4px 0 rgba(0,0,0,0.12),
                0 1px 8px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-4dp {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.14),
                0 4px 5px 0 rgba(0,0,0,0.12),
                0 1px 10px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-6dp {
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14),
                0 1px 18px 0 rgba(0,0,0,0.12),
                0 3px 5px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-8dp {
    box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14),
                0 3px 14px 3px rgba(0,0,0,0.12),
                0 4px 5px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-9dp {
    box-shadow: 0 9px 12px 1px rgba(0,0,0,0.14),
                0 3px 16px 2px rgba(0,0,0,0.12),
                0 5px 6px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-12dp {
    box-shadow: 0 12px 17px 2px rgba(0,0,0,0.14),
                0 5px 22px 4px rgba(0,0,0,0.12),
                0 7px 8px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-16dp {
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),
                0 6px 30px 5px rgba(0,0,0,0.12),
                0 8px 10px 0 rgba(0,0,0,0.20);
  }
  
  .shadow-24dp {
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),
                0 9px 46px 8px rgba(0,0,0,0.12),
                0 11px 15px 0 rgba(0,0,0,0.20);
  }
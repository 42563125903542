.text-center {
  margin: 0 auto;
  text-align: center;
}

.ant-layout {
  background: none;
}

.ant-list-header {
  background: #404c58;
  color: white;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow: hidden;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  background: blue;
}

/* Bloco para personalizar a a paginação do antd */
body {
  font: 'Roboto', sans-serif;
  font-weight: 'normal';
}

.ant-table {
  font-size: 16px;
}

/* bloco css para cards */
.card-generico {
  width: 100%;
  height: 48vh;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  flex-direction: column;
  line-height: 48px;
}
